<!--
 * @Description: 供应商管理
 * @Author: 琢磨先生
 * @Date: 2022-05-29 16:14:05
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-07-16 10:37:07
-->

<template>
  <el-card class="box">
    <el-form ref="query" :model="query" :inline="true">
      <el-form-item label="关键字">
        <el-input v-model="query.q" placeholder="请输入名称"></el-input>
      </el-form-item>
      <el-form-item label="等级">
        <el-select v-model="query.level" placeholder="" clearable>
          <el-option
            :label="item.text"
            :value="item.value"
            v-for="item in levels"
            :key="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" round @click="onSearch" icon="search"
          >查询</el-button
        >
      </el-form-item>
    </el-form>
    <el-button type="primary" size="small" @click="onEdit()" round icon="plus"
      >新增</el-button
    >
  </el-card>
  <el-card class="box data">
    <el-table :data="tableData.data" border @sort-change="onSort">
      <el-table-column prop="id" label="编号" width="80" sortable>
      </el-table-column>

      <el-table-column label="头像" width="100">
        <template #default="scope">
          <el-avatar
            :src="scope.row.logo_url ? scope.row.logo_url : avatar"
          ></el-avatar>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="名称" width="200" sortable>
      </el-table-column>
      <el-table-column prop="level_text" label="等级" width="100" sortable>
      </el-table-column>
      <el-table-column prop="address" label="地址" width="200">
      </el-table-column>
      <el-table-column prop="contact" label="联系人" width="100">
      </el-table-column>
      <el-table-column prop="mobile_phone" label="手机号" width="140">
      </el-table-column>
      <el-table-column label="状态" width="80">
        <template #default="scope">
          <el-tag type="success" v-if="scope.row.status == 0">正常</el-tag>
          <el-tag type="danger" v-if="scope.row.status == 10">停用</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="bank_name" label="银行名称" width="180">
      </el-table-column>
      <el-table-column prop="bank_account" label="银行账号" width="180">
      </el-table-column>
      <el-table-column prop="bank_account_name" label="开户名" width="180">
      </el-table-column>

      <el-table-column prop="create_at" label="创建时间" width="180">
      </el-table-column>
      <el-table-column label="操作" fixed="right" width="180">
        <template #default="scope">
          <el-button type="text" size="small" @click="onEdit(scope.row)"
            >修改</el-button
          >
          <el-button type="text" size="small" @click="onDtl(scope.row)"
            >详情</el-button
          >
          <el-popconfirm title="去定要删除？" @confirm="onDelete(scope.row)">
            <template #reference>
              <el-button type="text" size="small" class="text-danger"
                >删除</el-button
              >
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      v-if="tableData.counts > 0"
      @size-change="pageSizeChange"
      @current-change="pageCurrentChange"
      :current-page="query.pageIndex"
      :page-sizes="[20, 40, 60, 80, 100]"
      :page-size="query.pageSize"
      layout="total, sizes, prev, pager, next"
      :total="tableData.counts"
    >
    </el-pagination>
  </el-card>
  <edit-supplier
    :item="current"
    @reload="loadData"
    :levels="levels"
  ></edit-supplier>
  <supplier-dtl :dtl="dtlItem" @close="dtlClose"></supplier-dtl>
</template>

<script>
import avatar from "@/assets/avatar.png";
import EditSupplier from "./edit_supplier.vue";
import SupplierDtl from "./supplier_dtl.vue";
import common_api from "@/http/common_api";

export default {
  components: {
    EditSupplier,
    SupplierDtl,
  },
  data() {
    return {
      loading: false,
      current: null,
      dtlItem: null,
      //本地默认头像
      avatar: avatar,
      levels: [],
      query: {
        pageIndex: 1,
        pageSize: 20,
        order: {
          sort: "",
          fieldName: "",
        },
        q: "",
      },
      tableData: {
        counts: 0,
      },
    };
  },
  created() {
    /**
     *
     */
    common_api.get_enums().then((res) => {
      if (res.code == 0) {
        this.levels = res.data.supplier_levels;
      }
    });
    this.loadData();
  },
  methods: {
    /**
     * 搜索数据
     */
    onSearch() {
      this.query.pageIndex = 1;
      this.loadData();
    },
    /**
     * 加载数据
     */
    loadData() {
      this.loading = true;
      this.current = null;
      this.$http.post("seller/v1/supplier", this.query).then((res) => {
        if (res.code == 0) {
          this.tableData = res.data;
        }
        this.loading = false;
      });
    },
    /**
     * 排序
     */
    onSort(e) {
      if (e.prop) {
        this.query.order.sort = e.order;
        this.query.order.fieldName = e.prop;
        this.loadData();
      }
    },
    /**
     * 分页页数更改
     */
    pageSizeChange(val) {
      this.query.pageSize = val;
      this.loadData();
    },
    /**
     * 分页页码更改
     */
    pageCurrentChange(val) {
      this.query.pageIndex = val;
      this.loadData();
    },
    /**
     * 新增、修改
     */
    onEdit(item) {
      this.current = item ? item : {};
    },
    /**
     * 删除
     * @param {*} item
     */
    onDelete(item) {
      this.$http.get("seller/v1/supplier/del?id=" + item.id).then((res) => {
        if (res.code == 0) {
          this.$message({ type: "success", message: res.msg });
          this.loadData();
        }
      });
    },
    /**
     *
     * @param {*} item
     */
    onDtl(item) {
      this.dtlItem = item;
    },
    /**
     *
     */
    dtlClose() {
      this.dtlItem = null;
    },
  },
};
</script>

<style scoped>

</style>
