<!--
 * @Description: 供应商详情
 * @Author: 琢磨先生
 * @Date: 2022-05-29 23:08:36
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-05-30 01:56:56
-->

<template>
  <el-dialog
    :title="title"
    v-model="visible"
    width="800px"
    :before-close="beforeClose"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <div v-loading="loading">
      <el-descriptions title="">
        <el-descriptions-item label="名称">{{
          info.name
        }}</el-descriptions-item>
        <el-descriptions-item label="等级">{{
          info.level_text
        }}</el-descriptions-item>
        <el-descriptions-item label="地址">{{
          info.address
        }}</el-descriptions-item>
        <el-descriptions-item label="联系人">
          {{ info.contact }}
        </el-descriptions-item>
        <el-descriptions-item label="手机号">{{
          info.mobile_phone
        }}</el-descriptions-item>
        <el-descriptions-item label="状态"
          ><el-tag type="success" v-if="info.status == 0">正常</el-tag>
          <el-tag type="danger" v-if="info.status == 10"
            >停用</el-tag
          ></el-descriptions-item
        >
        <el-descriptions-item label="银行名称">{{
          info.bank_name
        }}</el-descriptions-item>
        <el-descriptions-item label="银行账号">{{
          info.bank_account
        }}</el-descriptions-item>
        <el-descriptions-item label="开户名">{{
          info.bank_account_name
        }}</el-descriptions-item>
        <el-descriptions-item label="创建时间">{{
          info.create_at
        }}</el-descriptions-item>
      </el-descriptions>

      <el-divider content-position="left">附件</el-divider>
      <el-table :data="info.files">
        <el-table-column label="文件名">
          <template #default="scope">
            <el-link type="primary" :href="scope.row.file_url">{{
              scope.row.name
            }}</el-link>
          </template>
        </el-table-column>
        <el-table-column label="" width="140">
          <template #default="scope">
            <el-popconfirm title="去定要删除？" @confirm="onDelete(scope.row)">
              <template #reference>
                <el-button type="text" size="small" class="text-danger"
                  >删除</el-button
                >
              </template>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <upload-file @change="uploadChange"></upload-file>
    </div>
  </el-dialog>
</template>

<script>
import UploadFile from "../../upload/upload_file.vue";

export default {
  components: {
    UploadFile,
  },
  data() {
    return {
      visible: false,
      loading: false,
      title: "",
      form: {},
      info: {},
    };
  },
  emits: ["close"],
  props: ["dtl"],
  watch: {
    dtl: {
      handler() {
        if (this.dtl) {
          this.visible = true;
          if (this.dtl.id) {
            this.form = Object.assign({}, this.dtl);
            this.title = "供应商详情";
            this.loadDtl();
          }
        }
      },
      immediate: true,
    },
  },
  methods: {
    /**
     *
     */
    loadDtl() {
      this.$http
        .get("seller/v1/supplier/dtl?id=" + this.form.id)
        .then((res) => {
          if (res.code == 0) {
            this.info = res.data;
          }
          this.loading = false;
        });
    },
    beforeClose() {
      this.visible = false;
      this.$emit("close");
    },
    /**
     * 删除文件
     * @param {*} item
     */
    onDelete(item) {
      this.$http
        .get("seller/v1/supplier/file/del?id=" + item.id)
        .then((res) => {
          if (res.code == 0) {
            this.$message({
              type: "success",
              message: res.msg,
            });
            var i = this.info.files.findIndex((o) => o.id == item.id);
            this.info.files.splice(i, 1);
          }
        });
    },
    /**
     * 上传成功回调
     * @param {*} file
     */
    uploadChange(file) {
      var data = {
        name: file.name,
        supplier_id: this.form.id,
        file_url: file.preview_url,
        ext: file.ext,
      };
      console.log(data);
      this.$http.post("seller/v1/supplier/file/add", data).then((res) => {
        if (res.code == 0) {
          this.loadDtl();
        }
      });
    },
  },
};
</script>

<style scoped>
</style>
