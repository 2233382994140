<!--
 * @Description: 上传文件，附件上传至 部署的 MINIO
 * @Author: 琢磨先生
 * @Date: 2022-05-29 21:10:34
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-05-30 00:02:18
-->


<template>
  <el-upload
    ref="upload"
    class="upload"
    drag
    action=""
    :http-request="upload"
    multiple
    :on-remove="handlerRemove"
  >
    <el-icon class="el-icon--upload"><upload-filled /></el-icon>
    <div class="el-upload__text">拖拽至此 或<em>点击</em>上传</div>
    <template #tip>
    </template>
  </el-upload>
</template>

<script>
import common_api from "@/http/common_api";
import axios from "axios";

export default {
  data() {
    return {
      fileList: [],
    };
  },
  emits: ["change", "remove"],
  props: ["show"],
  methods: {
    upload(ctx) {
      console.log(ctx);
      var i = ctx.file.name.lastIndexOf(".");
      var ext = ctx.file.name.substring(i);
      ctx.file.ext = ext;
      common_api.get_upload_url(ext).then((res) => {
        if (res.code == 0) {
          axios
            .put(res.data.upload_url, ctx.file, {
              //上传进度
              onUploadProgress: (progressEvent) => {
                let num =
                  ((progressEvent.loaded / progressEvent.total) * 100) | 0;
                ctx.onProgress({ percent: num });
              },
            })
            .then(() => {
              //上传成功
              ctx.onSuccess();
              ctx.file.preview_url = res.data.preview_url;
              this.$emit("change", ctx.file);
              if (!this.show) {
                this.$refs.upload.handleRemove(ctx.file);
              }
            });
        } else {
          //移除文件
          this.$refs.upload.handleRemove(ctx.file);
        }
      });
    },
    /**
     * 删除文件
     */
    handlerRemove(file) {
      this.$emit("remove", file.raw);
    },
  },
};
</script>

<style  >
.upload {
  width: 100%;
}
.upload .el-upload {
  width: 100%;
}
.upload .el-upload .el-upload-dragger {
  width: 100%;
}
</style>