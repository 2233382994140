<!--
 * @Description: 新增、修改供应商
 * @Author: 琢磨先生
 * @Date: 2022-05-29 16:14:23
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-05-30 00:03:35
-->

<template>
  <el-dialog
    ref="upload"
    v-model="visible"
    :title="title"
    width="800px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
  >
    <el-form :model="form" ref="form" :rules="rules" label-width="140px">
      <el-form-item label="名称" prop="name">
        <el-col :span="14">
          <el-input v-model="form.name" placeholder="请输入"></el-input
        ></el-col>
      </el-form-item>
      <el-form-item label="地址" prop="address">
        <el-col :span="20">
          <el-input v-model="form.address" placeholder="供应商地址"></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="等级">
        <el-radio-group v-model="form.level">
          <el-radio
            :label="item.value"
            v-for="item in levels"
            :key="item.value"
            >{{ item.text }}</el-radio
          >
        </el-radio-group>
      </el-form-item>
      <el-form-item label="联系人" prop="contact">
        <el-col :span="14">
          <el-input
            v-model="form.contact"
            placeholder="请输入联系人"
          ></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="手机号码" prop="mobile_phone">
        <el-col :span="14">
          <el-input
            v-model="form.mobile_phone"
            placeholder="请输入手机号码"
          ></el-input>
        </el-col>
      </el-form-item>

      <el-form-item label="银行" prop="bank_name">
        <el-col :span="14">
          <el-input v-model="form.bank_name" placeholder=""></el-input>
        </el-col>
      </el-form-item>

      <el-form-item label="银行账号" prop="bank_account">
        <el-col :span="18">
          <el-input v-model="form.bank_account" placeholder=""></el-input>
        </el-col>
      </el-form-item>

      <el-form-item label="开户名" prop="bank_account_name">
        <el-col :span="14">
          <el-input v-model="form.bank_account_name" placeholder=""></el-input>
        </el-col>
      </el-form-item>

      <el-form-item label="停用" prop="is_stop">
        <el-switch v-model="form.is_stop"></el-switch>
      </el-form-item>
    </el-form>

    <div v-if="!form.id">
      <el-divider content-position="left">附件</el-divider>
      <upload-file
        @change="uploadChange"
        @remove="removeFile"
        :show="true"
      ></upload-file>
    </div>

    <template #footer>
      <el-button type="primary" @click="onSubmit" :loading="saving"
        >确定</el-button
      >
      <el-button @click="cancelClick" :disabled="saving">取消</el-button>
    </template>
  </el-dialog>
</template>

<script>
import UploadFile from "../../upload/upload_file.vue";
export default {
  components: {
    UploadFile,
  },
  data() {
    return {
      loading: false,
      saving: false,
      visible: false,
      title: "",
      files: [],
      form: {
        level: 0,
      },
      rules: {
        name: [
          {
            required: true,
            message: "请输入名称",
            trigger: "blur",
          },
        ],
        contact: [
          {
            required: true,
            message: "请输入姓名",
            trigger: "blur",
          },
        ],
        mobile_phone: [
          {
            required: true,
            message: "请输入手机号码",
            trigger: "blur",
          },
          {
            pattern: /^1[23456789]\d{9}$/,
            message: "手机号码格式错误",
            trigger: "blur",
          },
        ],
      },
    };
  },
  emits: ["reload"],
  props: ["item", "levels"],
  watch: {
    item: {
      handler() {
        if (this.item) {
          this.visible = true;
          if (this.item.id) {
            this.form = Object.assign({}, this.item);
            this.title = "修改供应商";
          } else {
            this.title = "新增供应商";
          }
        }
      },
      immediate: true,
    },
  },
  created() {},
  methods: {
    /**
     * 提交保存
     */
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          console.log(this.form);
          this.form.files = this.files;
          this.saving = true;
          this.$http
            .post("seller/v1/supplier/edit", this.form)
            .then((res) => {
              this.saving = false;
              if (res.code == 0) {
                this.visible = false;
                this.$message({
                  type: "success",
                  message: res.msg,
                });
                this.$emit("reload");
              }
            })
            .cath(() => {
              this.saving = false;
            });
        }
      });
    },
    /**
     * 取消
     */
    cancelClick() {
      this.visible = false;
      this.$emit("reload");
    },
    /**
     * 上传成功回调
     * @param {*} file
     */
    uploadChange(file) {
      this.files.push({
        name: file.name,
        file_url: file.preview_url,
        ext: file.ext,
      });
    },
    /**
     * 移除文件
     * @param {*} file
     */
    removeFile(file) {
      var i = this.files.findIndex((o) => o.file_url == file.preview_url);
      this.files.splice(i, 1);
      console.log(this.files);
    },
  },
};
</script>

<style   scoped>
</style>